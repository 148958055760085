import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from '../components/common/section';
import ContentHero from '../components/content-hero';

import SubFeatureDetail from '../components/subfeature-detail';

import Ready from '../components/ready';

import illuReady from '../images/illu-ready.svg';
import illuHero from "../images/organize-together-pitch_top.svg"
import illuHero2 from "../images/sub-product-tour-1.svg"

const ContentSample = () => {
  const shareAnimation = '/animations/2020-09-Ludus-11-share_EDIT01.json';
  const embedAnimation = '/animations/2020-09-Ludus-10-embed_EDIT01.json';
  const speakerAnimation = '/animations/2020-09-Ludus-06-speaker_EDIT01.json';

  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null
  });

  return (
    <Layout
      socialDark={true}
      currentPage='pitch-in-peace'
      updateBreakpoint={setCurrentBreakpointChildren}
    >
      <SEO title="Pitch in peace" />

      <Section>

        <div
          style={{
            display: "block",
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === 'mobile' ? '43%' : '24%',
            paddingBottom: currentBreakPoint.type === "mobile" ? '75%' : '27%',
            top: currentBreakPoint.type === 'mobile' ? '130px' : '190px',
            right: '0',
            background: `transparent url(${illuHero}) no-repeat 100% 50%`,
            backgroundSize: "contain",
          }}
        />

        <ContentHero
          headlineLabel="Product tour"
          headlineUrl="/product-tour"
          title="Pitch in peace"
          text="The time has come to share your slides with the world. It used to be scary (is it the latest version? Will it be readable by my very important client? Will it fall into spam?). But with Ludus you can share your presentation with complete peace of mind, it will just work, and it will always be the latest version."
        />
      </Section>

      <Section>

        <div
          style={{
            display: "block",
            position: "absolute",
            zIndex: "0",
            width: currentBreakPoint.type === "mobile" ? '40%' : '20%',
            paddingBottom: currentBreakPoint.type === "mobile" ? '75%' : '27%',
            top: '-5%',
            left: '0',
            background: `transparent url(${illuHero2}) no-repeat 0 50%`,
            backgroundSize: "contain",
          }}
        />

        <SubFeatureDetail
          title="Custom domain and URL"
          text="When you share a presentation, the first impression is key. That's why we let you customise up to the domain, your presentation URL... Your presentations never looked so professional!"
          details={[
            {
              title: 'Custom URL',
              text: `In Ludus, each presentation has a unique URL, like app.ludus.one/20981098xxx. You can share this straight away of course. But if you need to add a special touch you can customize the last part: app.ludus.one/my_beautifull_presentation so your recipient will be more likely to open it.`
            },
            {
              title: 'Make it your very own',
              text: `With our custom domain, you can make Ludus your very own, and nobody will never now you used Ludus when you share your presentation: you can then transfrom app.ludus.one into slides.mydomain.com (or whatever your imagination tells you)`
            }
          ]}
          animation={shareAnimation}
        />
      </Section>

      <Section>
        <SubFeatureDetail
          reverse={true}
          title="Embed anywhere"
          text="Being able to share a presentation with just a link is very nice, but sometimes you need to go further. Especially when you use other online tools, you would like to embed your presentation into your todo list, or your project management tool... Well it's possible and quite easy."
          details={[
            {
              title: 'Everybody can do it',
              text: `It may looks a bit scary, but an embed code is very easy to use. You just copy paste the code directly into your website html pages, as you would embed a YouTube video. `
            },
            {
              title: 'oEmbed compatible',
              text: `And as we're fully oEmbed compatible, you can also embed Ludus presentations in your Medium articles, your Notion documents, or any other place that supports oEmbed.`
            }
          ]}
          animation={embedAnimation}
        />
      </Section>

      <Section>
        <SubFeatureDetail
          title="Ludus Vox"
          text="Don’t let your slides speak for themselves ever again. With Ludus Vox, you can record yourself on top of your slides to add more context to your presentation, the same way you would pitch live to an audience. "
          details={[
            {
              title: 'Video and audio',
              text: `The system lets you record one video per slide, allowing the watcher to be in control and easily switch to another slide or play back something they did not understand at first.`
            },
            {
              title: 'The viewer remain in control',
              text: `Of course the viewer is not forced to look at your beautiful face, and listen to your beautiful voice. Just think of it subtitles of a video... `
            }
          ]}
          animation={speakerAnimation}
        />
      </Section>

      <Section>
        <br /><br /><br /><br /><br /><br /><br /><br /><br />
      </Section>

      <Section
        light={true}
      >
        <div style={{
          display: (currentBreakPoint.bp === 'm' || currentBreakPoint.bp === 's' || currentBreakPoint.bp === 'xs') ? 'none' : 'block',
          position: 'absolute',
          zIndex: '4',
          width: '35%',
          paddingBottom: '35%',
          bottom: '0',
          right: '0',
          background: `transparent url(${illuReady}) no-repeat 100% 50%`,
          backgroundSize: 'contain',
        }} />
        <Ready />
      </Section>

      
    </Layout>
  );
};

export default ContentSample;
